import '../../res/css/ui.css'
import '../../res/css/mobile_cumm.css'
import '../../res/css/desktop_cumm.css'

const Footer = () => {
    return (
        <div id="footer">
            <div className="footer_inner">
                <ul className="list_policy">
                    <li><a target="_blank" title="새창">이용약관</a></li>
                    <li><a target="_blank" title="새창">개인정보처리방침</a></li>
                </ul>
                <address>
                    <p>
                        <span>MUSICKNOCK 뮤직노크</span>
                        <span>대표자 윤주아</span>
                        <span>yunjua@musicknock.co.kr</span>
                    </p>
                    <p><span>인천시 미추홀구 석정로 229, JST제물포스마트타운 5층 508호</span></p>
                </address>
                <p className="copyright">Copyright © 2023 MUSICKNOCK All Rights Reserved.</p>
            </div>
        </div>
    )
}
export default Footer