import '../../res/css/ui.css'
import '../../res/css/mobile_cumm.css'
import '../../res/css/desktop_cumm.css'

const Home = () => {
    return (
        <div>
            <div className="artist_search_wrap">
                <div className="inner">
                    <h2><span className="col_mint">MUSIC KNOCK</span></h2>
                    <h3><span className="col_orange">Happiness and joy with Music Knock</span></h3>
                    <h2><span className="col_white2">시간과 공간의 제약 없이 피아노를 즐겁게 배워보세요</span></h2>
                </div>
            </div>
            <div className="col_font_outer">
                <div className="col_font">
                    <h1><b>COMING SOON</b></h1>
                </div>
            </div>
        </div>
    )
}

export default Home