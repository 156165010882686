import '../../res/css/ui.css'
import '../../res/css/mobile_cumm.css'
import '../../res/css/desktop_cumm.css'

const Header = () => {
    return (
        <div id="header">
            <div className="header_inner">
                <h1 className="logo"><a>뮤직노크</a></h1>
            </div>
        </div>
    )
}

export default Header