import React from "react";
import Footer from "./footer/Footer";
import Header from "./header/Header";
import Home from "./home/Home";

const Layout = (props: {
    children: React.ReactNode
}) => {
    return (
        <div id="wrap">
            <Header />
            <Home />
            <Footer />
        </div>
    )
}

export default Layout